export const TRANSLATIONS_PT = {
    // COMMON
    fieldRequired: "Este campo é obrigatório",
    fieldInvalid: "Este campo é inválido",
    fieldMaxLimit: "São necessários menos de {{limit}} caracteres",
    fieldMaxValueLimit: "O valor deve ser menor que {{limit}}",
    fieldMinValueLimit: "O valor deve ser maior que {{limit}}",
    fieldEmailDuplicated: "E-mail duplicado",
    fieldConfirmPasswordCorrectly: "Confirme a senha corretamente",

    success: "Sucesso",
    warning: "Aviso",
    error: "Erro",

    // HEADER
    myProfile: "Meu perfil",
    signOut: "Sair",

    // SIDER
    dashboard: "Painel",
    users: "Usuários",
    devices: "Dispositivos",
    balance: "Equilíbrio",
    transactions: "Transações",
    withdrawals: "Retirados",
    commissions: "Comissões",
    configuration: "Configuração",
    store: "Loja",
    products: "Produtos",
    orders: "Pedidos",

    // LOGIN
    email: "E-mail",
    password: "Senha",
    keepMeSignedIn: "Mantenha-me conectado",
    signIn: "Entrar",
    getCodeFromYourEmail: "Obtenha o código do seu e-mail",
    getCodeFromYourAuthenticatorApp: "Obtenha o código do seu aplicativo autenticador",
    enterCodeFromYourEmail: "Digite o código do seu e-mail",
    verify: "Verificar",
    resendCode: "Reenviar código",
    verificationCodeSentSuccessfully: "O código de verificação foi enviado com sucesso",
    accountSuspendedError:
        "Esta conta foi suspensa. Entre em contato com o suporte em <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",

    // DASHBOARD
    total: "Total",
    wallet: "Carteira",
    incoming: "Entrada",
    commission: "Comissão",
    withdrawal: "Cancelamento",
    consumption: "Consumo",
    monthly: "Por mês",
    daily: "Diário",
    inboundCapacity: "Capacidade de entrada",
    outboundCapacity: "Capacidade de saída",

    // USERS
    confirmDeleteUser: "Tem certeza que deseja excluir este usuário?",
    delete: "Excluir",
    cancel: "Cancelar",
    suspendUser: "Suspender usuário",
    reason: "Razão",
    suspend: "Suspender",
    newUser: "Novo usuário",
    searchByEmailOrFullname: "Pesquise por e-mail ou nome completo",
    selectRole: "Selecione a função",
    all: "Todos",
    startDate: "Data de início",
    finishDate: "Data de término",
    fullname: "Nome completo",
    role: "Papel",
    phone: "Telefone",
    birthday: "Aniversário",
    createdAt: "Criado em",
    isActive: "Está ativo",
    active: "Ativo",
    inactive: "Inativo",
    suspended: "Suspenso",
    yes: "Sim",
    no: "Não",
    restore: "Restaurar",
    confirmRestoreUser: "Tem certeza de que deseja restaurar esta conta de usuário?",
    userRole1: "Supergerente",
    userRole2: "Gerente",
    userRole3: "Administrador",
    userRole4: "Membro",
    edit: "Editar",
    resetPassword: "Redefinir senha",
    noUser: "Nenhum usuário",
    updateUser: "Atualizar usuário",
    createUser: "Criar usuário",
    saveChanges: "Salvar alterações",
    create: "Criar",
    confirmPassword: "Confirme sua senha",
    reset: "Reiniciar",
    lastLogin: "Último Login",
    emailVerified: "Email verificado",
    phoneVerified: "Telefone verificado",

    // DEVICE
    search: "Procurar",
    paringCode: "Código de emparelhamento",
    marketType: "Tipo de mercado",
    printPurchase: "Compra de impressão",
    receiveTip: "Receber dica",
    confirmDeleteDevice: "Tem certeza de que deseja excluir este dispositivo?",
    noDevices: "Nenhum dispositivo",
    selectUser: "Selecione o usuário",

    // BALANCE
    adminEmail: "E-mail do administrador",
    adminName: "Nome do administrador",
    noBalance: "Nenhum equilibrio",

    // TRANSACTIONS
    downloadAsExcel: "Baixe como Excel",
    date: "Data",
    admin: "Administrador",
    company: "Empresa",
    device: "Dispositivo",
    description: "Descrição",
    amount: "Quantia",
    currency: "Moeda",
    noTransactions: "Nenhuma transação",
    outboundLiquidity: "Liquidez de saída",
    inboundLiquidity: "Liquidez de entrada",
    hashLog: "Registro de hash",

    // WITHDRAWALS
    selectStatus: "Selecione o estado",
    withdrawalStatus_initiated: "Iniciado",
    withdrawalStatus_in_review: "Em revisão",
    withdrawalStatus_in_process: "Em processo",
    withdrawalStatus_finished: "Finalizado",
    withdrawalStatus_rejected: "Rejeitado",
    withdrawalStatusDescription_initiated: "Solicitação de saque é criada, aguardando a análise do gerente",
    withdrawalStatusDescription_in_review: "A solicitação de retirada está sob análise do gerente",
    withdrawalStatusDescription_in_process: "A solicitação de saque está em andamento",
    withdrawalStatusDescription_finished: "O processo de retirada está concluído",
    withdrawalStatusDescription_rejected: "A solicitação de saque foi rejeitada",
    selectManager: "Selecione o gerente",
    bank: "Banco",
    account: "Conta",
    status: "Status",
    manager: "Gerente",
    seeBankDetail: "Ver dados bancários",
    seeBitcoinAddressDetail: "Ver detalhes do endereço bitcoin",
    noWithdrawal: "Sem retirada",
    setAsInReview: "Definir como Em análise",
    setAsInProcess: "Definir como Em processo",
    finishWithdrawal: "Finalizar retirada",
    approveWithdrawal: "Aprovar retirada",
    confirmApproveLightningWithdrawal:
        "Tem certeza de que aprova esta solicitação de saque? Depois de aprovar, a solicitação de saque será processada e será a operação final.",
    rejectWithdrawal: "Rejeitar retirada",
    bankAccountDetail: "Detalhes da conta bancária",
    bankName: "Nome do banco",
    swiftCode: "Código Swift",
    accountNumber: "Número de conta",
    accountName: "Nome da conta",
    address: "Endereço",
    zipCode: "CEP",
    close: "Fechar",
    bitcoinAccountDetail: "Detalhes da conta Bitcoin",
    nickName: "Apelido",
    lightningAddress: "Endereço Lightning",
    lightningAddressDetail: "Lightning Detalhes do endereço",

    // COMMISSIONS
    commissionType: "Tipo de comissão",
    minimumPurchase: "Compra mínima",
    commissionRunOverThisAmount: "Comissão ultrapassa este valor",
    fixCommissionAmount: "Corrigir valor da comissão",
    commissionPercentage: "Porcentagem de comissão",
    noCommission: "Sem comissão",
    updateCommission: "Comissão de atualização",
    save: "Salvar",

    // CONFIGURATION
    baseCommission: "Comissão base",
    marketTypes: "Tipos de mercado",
    name: "Nome",
    noMarketTypes: "Nenhum tipo de mercado",
    newMarketType: "Novo tipo de mercado",
    createMarketType: "Criar tipo de mercado",
    updateMarketType: "Atualizar tipo de mercado",
    confirmDeleteMarketType: "Tem certeza de que deseja excluir este tipo de mercado?",

    // RODPAY WITHDRAWAL
    makeWithdrawal: "Fazer saque",

    // PROFILE
    generalInformation: "Informações gerais",
    avatar: "Avatar",
    security: "Segurança",
    "2fa": "Autenticação de dois fatores",
    "2faDescription":
        "A autenticação de dois fatores (2FA) é um método de segurança usado para proteger sua conta www.rodpay.io. O principal objetivo da autenticação de dois fatores é adicionar uma camada adicional de segurança além da senha tradicional. Ao utilizar o e-mail como segundo fator de autenticação, mesmo que alguém consiga obter sua senha, não conseguirá acessar sua conta sem ter acesso ao seu e-mail para obter o código.",
    allow2fa: "Ative a autenticação de dois fatores (2FA)",
    setup2FASuccess: "A configuração da autenticação de dois fatores (2FA) foi bem-sucedida",
    confirmDisable2fa: "Tem certeza de que deseja desativar a autenticação de dois fatores (2FA)?",
    disable2fa: "Desative a autenticação de dois fatores (2FA)",
    inputCode: "Código de entrada",
    "2faSetupGuide":
        "Leia o código QR abaixo usando seu aplicativo autenticador, como o Google Authenticator, e insira o código.",
    passwordRule: "São necessários 8 caracteres e pelo menos 1 caractere especial(!@#$&*)",

    // USER
    knowYourCustomer: "Conheça seu cliente",
    nationalID: "Identidade nacional",
    nationalIDNotUploaded: "O documento de identificação nacional não foi carregado",
    download: "Download",
    approve: "Aprovar",
    reject: "Rejeitar",
    approved: "Aprovado",
    rejected: "Rejeitado",
    companyDUNS: "Empresa DUNS",
    companyDUNSNotUploaded: "A empresa DUNS não foi carregada",

    // USER LOG
    actionLogs: "Registros de ação",
    action: "Ação",
    model: "Modelo",
    detail: "Detalhe",
    user_action_create: "Criar",
    user_action_update: "Atualizar",
    user_action_delete: "Excluir",
    user_action_soft_delete: "Excluir",
    user_action_restore: "Restaurar",
    user_action_reset_password: "Redefinir senha",
    user_action_tfa_enable: "Habilitar 2FA",
    user_action_tfa_disable: "Desativar 2FA",
    user_action_update_profile: "Atualizar perfil",
    user_action_invoice_paid: "Fatura paga",
    user_action_invoice_expired: "Fatura expirou",
    user_action_withdrawal_in_review: "Revisar retirada",
    user_action_withdrawal_in_process: "Prosseguir com a retirada",
    user_action_withdrawal_finished: "Concluir retirada",
    user_action_withdrawal_rejected: "Rejeitar retirada",

    // PRODUCTS
    image: "Imagem",
    price: "Preço",
    stock: "Estoque",
    newProduct: "Novo produto",
    noProduct: "Nenhum produto",
    confirmDelete: "Você tem certeza que quer deletar?",
    createProduct: "Criar produto",
    updateProduct: "Atualizar produto",
    selectFile: "Selecione o arquivo",
    totalOrders: "Total de pedidos",
    totalReviews: "Total de avaliações",
    files: "Arquivos",
    createNewFile: "Criar novo arquivo",
    type: "Tipo",
    url: "URL",
    updateFile: "Atualizar arquivo",
    file: "Arquivo",
    pdf: "PDF",
    video: "Vídeo",
    weight: "Peso",
    rank: "Classificação",
    noFile: "Nenhum arquivo",
    user: "Do utilizador",
    noReview: "Sem revisão",
    order: "Ordem",

    // ORDERS
    initiated: "Iniciado",
    paid: "Pago",
    shipping: "Envio",
    succeeded: "Sucesso",
    canceled: "Cancelado",
    orderDetail: "Detalhe do pedido",
    needToStartDelivery: "Precisa iniciar o processo de entrega",
    in_process: "Em processo",
    shipment: "Envio",
    note: "Observação",
    country: "País",
    state: "Estado",
    city: "Cidade",
    apartment: "Apartamento",
    postalCode: "Código postal",
    product: "Produtos",
    quantity: "Quantidade",
    transaction: "Transação",
    noTransactionHistory: "Sem histórico de transações",
    cancelOrder: "Cancelar pedido",
    startDelivery: "Iniciar entrega",
    confirmCancelOrder: "Tem certeza de que deseja cancelar este pedido?",
    confirm: "Confirme",
    method: "Método",
    unpaid: "Não pago",
    paidAt: "Pago em",
    reviews: "Avaliações",
    rating: "Avaliação",
    message: "Mensagem",
};
