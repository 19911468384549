/** ------------------- */
// ENV VARIABLES
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

/** ------------------- */
// COLORS
export const bhColorWhite = "#FFF";
export const bhColorGray = "#F1F0F3";
export const bhColorDarkGray = "#D1D1D1";
export const bhColorVioletGray = "#6C6685";
export const bhColorDark = "#212121";
export const bhColorBlack = "#000";

export const bhColorError = "#FF4D4F";
export const bhColorWarning = "#FB5400";
export const bhColorSuccess = "#8200FF";
export const bhColorSuccess1 = "#0017ff";
export const bhColorPrimary = "#FF6C2F";
export const bhColorLightPrimary = "rgba(255, 108, 47, 0.25)";

export const bhColorRed = "#FF4D4F";
export const bhColorOrange = "#FF6C2F";
export const bhColorYellow = "yellow";
export const bhColorGreen = "green";
export const bhColorBlue = "blue";
export const bhColorPurple = "purple";

/** ------------------- */
// LOCALSTORAGE KEYS
export const LS_AUTH_TOKEN_KEY = "auth_token";

/** ------------------- */
// SYSTEM CONSTANTS
export const currencies = [
    {
        code: "SATS",
        label: "Satoshi",
    },
    {
        code: "USD",
        label: "USD",
    },
    {
        code: "CLP",
        label: "CLP",
    },
    {
        code: "ARS",
        label: "ARS",
    },
    {
        code: "BRL",
        label: "BRL",
    },
    {
        code: "MXN",
        label: "MXN",
    },
];

export const withdrawalStatuses = ["initiated", "in_review", "in_process", "finished", "rejected"];

export const orderStatusColorsMap = {
    initiated: "orange",
    paid: "blue",
    shipping: "purple",
    succeeded: "green",
    canceled: "red",
};

export const shipmentStatusColorsMap = {
    initiated: "orange",
    in_process: "purple",
    succeeded: "green",
    canceled: "red",
};

export const needToHideModelActions = ["tfa_enable", "tfa_disable", "update_profile"];

export const needToHideDetailActions = [
    "delete",
    "soft_delete",
    "restore",
    "reset_password",
    "tfa_enable",
    "tfa_disable",
    "invoice_paid",
    "invoice_expired",
    "withdrawal_in_review",
    "withdrawal_in_process",
    "withdrawal_finished",
    "withdrawal_rejected",
];
