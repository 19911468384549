import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    baseCommission: null,
    allMarketTypes: null,
    marketTypes: null,
    marketTypeTotal: 0,
    marketTypeFilter: {
        page: 1,
        pageSize: 10,
    },
};

const updateBaseCommission = (state, action) => {
    return updateState(state, {
        baseCommission: action.baseCommission,
    });
};

const allMarketTypes = (state, action) => {
    return updateState(state, {
        allMarketTypes: action.allMarketTypes,
    });
};

const updateMarketTypes = (state, action) => {
    return updateState(state, {
        marketTypes: action.marketTypes,
        marketTypeTotal: action.marketTypeTotal,
    });
};

const updateMarketTypeFilter = (state, action) => {
    return updateState(state, {
        marketTypeFilter: action.marketTypeFilter,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_BASE_COMMISSION:
            return updateBaseCommission(state, action);
        case actionTypes.UPDATE_ALL_MARKET_TYPES:
            return allMarketTypes(state, action);
        case actionTypes.UPDATE_MARKET_TYPES:
            return updateMarketTypes(state, action);
        case actionTypes.UPDATE_MARKET_TYPE_FILTER:
            return updateMarketTypeFilter(state, action);
        default:
            return state;
    }
};

export default reducer;
