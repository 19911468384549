export const TRANSLATIONS_ES = {
    // COMMON
    fieldRequired: "Este campo es obligatorio",
    fieldInvalid: "Este campo no es válido",
    fieldMaxLimit: "Se requieren menos de {{limit}} caracteres",
    fieldMaxValueLimit: "El valor debe ser inferior a {{limit}}",
    fieldMinValueLimit: "El valor debe ser mayor que {{limit}}",
    fieldEmailDuplicated: "Correo electrónico duplicado",
    fieldConfirmPasswordCorrectly: "Confirmar contraseña correctamente",

    success: "Éxito",
    warning: "Advertencia",
    error: "Error",

    // HEADER
    myProfile: "Mi perfil",
    signOut: "Desconectar",

    // SIDER
    dashboard: "Panel",
    users: "Usuarios",
    devices: "Dispositivos",
    balance: "Balance",
    transactions: "Actas",
    withdrawals: "Retiros",
    commissions: "Comisiones",
    configuration: "Configuración",
    store: "Almacenar",
    products: "Productos",
    orders: "Pedidos",

    // LOGIN
    email: "Correo electrónico",
    password: "Contraseña",
    keepMeSignedIn: "Mantenerme registrado",
    signIn: "Iniciar sesión",
    getCodeFromYourEmail: "Obtenga el código de su correo electrónico",
    getCodeFromYourAuthenticatorApp: "Obtenga el código de su aplicación de autenticación",
    enterCodeFromYourEmail: "Introduce el código de tu correo electrónico",
    verify: "Verificar",
    resendCode: "Reenviar codigo",
    verificationCodeSentSuccessfully: "El código de verificación se envió con éxito",
    accountSuspendedError:
        "Esta cuenta está suspendida, comuníquese con el soporte técnico a <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",

    // DASHBOARD
    total: "Total",
    wallet: "Billetera",
    incoming: "Entrante",
    commission: "Comisión",
    withdrawal: "Retiro",
    consumption: "Consumo",
    monthly: "Mensual",
    daily: "A diario",
    inboundCapacity: "Capacidad entrante",
    outboundCapacity: "Capacidad de salida",

    // USERS
    confirmDeleteUser: "¿Estás seguro de eliminar a este usuario?",
    delete: "Borrar",
    cancel: "Cancelar",
    suspendUser: "Suspender usuario",
    reason: "Razón",
    suspend: "Suspender",
    newUser: "Nuevo usuario",
    searchByEmailOrFullname: "Buscar por correo electrónico o nombre completo",
    selectRole: "Seleccionar rol",
    all: "Todo",
    startDate: "Fecha de inicio",
    finishDate: "Fecha de finalización",
    fullname: "Nombre completo",
    role: "Role",
    phone: "Teléfono",
    birthday: "Cumpleaños",
    createdAt: "Creado en",
    isActive: "Está activo",
    active: "Activo",
    inactive: "Inactivo",
    suspended: "Suspendido",
    yes: "Sí",
    no: "No",
    restore: "Restaurar",
    confirmRestoreUser: "¿Estás seguro de restaurar esta cuenta de usuario?",
    userRole1: "Súper gerente",
    userRole2: "Gerente",
    userRole3: "Administrador",
    userRole4: "Miembro",
    edit: "Editar",
    resetPassword: "Restablecer la contraseña",
    noUser: "Sin usuario",
    updateUser: "Actualizar usuario",
    createUser: "Crear usuario",
    saveChanges: "Guardar cambios",
    create: "Crear",
    confirmPassword: "Confirmar contraseña",
    reset: "Reiniciar",
    lastLogin: "Último acceso",
    emailVerified: "Correo Electrónico Verificado",
    phoneVerified: "Teléfono verificado",

    // DEVICE
    search: "Buscar",
    paringCode: "Código de emparejamiento",
    marketType: "Tipo de mercado",
    printPurchase: "Imprimir compra",
    receiveTip: "Recibir propina",
    confirmDeleteDevice: "¿Estás seguro de eliminar este dispositivo?",
    noDevices: "Sin dispositivos",
    selectUser: "Seleccionar usuario",

    // BALANCE
    adminEmail: "Correo electrónico del administrador",
    adminName: "Nombre del administrador",
    noBalance: "Sin saldo",

    // TRANSACTIONS
    downloadAsExcel: "Descargar como Excel",
    date: "Fecha",
    admin: "Administrador",
    company: "Compañía",
    device: "Dispositivo",
    description: "Descripción",
    amount: "Cantidad",
    currency: "Divisa",
    noTransactions: "Sin transacciones",
    outboundLiquidity: "Liquidez saliente",
    inboundLiquidity: "Liquidez entrante",
    hashLog: "Registro hash",

    // WITHDRAWALS
    selectStatus: "Seleccionar estado",
    withdrawalStatus_initiated: "Iniciado",
    withdrawalStatus_in_review: "En revisión",
    withdrawalStatus_in_process: "En proceso",
    withdrawalStatus_finished: "Finalizado",
    withdrawalStatus_rejected: "Rechazado",
    // eslint-disable-next-line max-len
    withdrawalStatusDescription_initiated:
        "Se crea la solicitud de retiro, en espera de la revisión por parte del gerente",
    withdrawalStatusDescription_in_review: "La solicitud de retiro está siendo revisada por el gerente",
    withdrawalStatusDescription_in_process: "La solicitud de retiro está en proceso",
    withdrawalStatusDescription_finished: "El proceso de retiro ha finalizado",
    withdrawalStatusDescription_rejected: "La solicitud de retiro es rechazada",
    selectManager: "Seleccionar administrador",
    bank: "Banco",
    account: "Cuenta",
    status: "Estado",
    manager: "Gerente",
    seeBankDetail: "Ver detalle bancario",
    seeBitcoinAddressDetail: "Ver detalle de la dirección de bitcoin",
    noWithdrawal: "Sin retiro",
    setAsInReview: "Establecer como En revisión",
    setAsInProcess: "Establecer como En proceso",
    finishWithdrawal: "Terminar retiro",
    approveWithdrawal: "Aprobar retiro",
    confirmApproveLightningWithdrawal:
        "¿Está seguro de aprobar esta solicitud de retiro? Una vez que lo apruebe, la solicitud de retiro se procesará y será la operación final.",
    rejectWithdrawal: "Rechazar retiro",
    bankAccountDetail: "Detalle de la cuenta bancaria",
    bankName: "Nombre del banco",
    swiftCode: "Código SWIFT",
    accountNumber: "Número de cuenta",
    accountName: "Nombre de la cuenta",
    address: "Dirección",
    zipCode: "Código postal",
    close: "Cerca",
    bitcoinAccountDetail: "Detalle de la cuenta bitcoin",
    nickName: "Apodo",
    lightningAddress: "Dirección de Lightning",
    lightningAddressDetail: "Detalle de la dirección de Lightning",

    // COMMISSIONS
    commissionType: "Tipo de comisión",
    minimumPurchase: "Compra mínima",
    commissionRunOverThisAmount: "La comisión superó esta cantidad",
    fixCommissionAmount: "Fijar el monto de la comisión",
    commissionPercentage: "Porcentaje de comisión",
    noCommission: "Sin comisión",
    updateCommission: "Actualizar comisión",
    save: "Guardar",

    // CONFIGURATION
    baseCommission: "Comisión básica",
    marketTypes: "Tipos de mercado",
    name: "Nombre",
    noMarketTypes: "Sin tipos de mercado",
    newMarketType: "Nuevo tipo de mercado",
    createMarketType: "Crear tipo de mercado",
    updateMarketType: "Actualizar tipo de mercado",
    confirmDeleteMarketType: "¿Estás seguro de eliminar este tipo de mercado?",

    // RODPAY WITHDRAWAL
    makeWithdrawal: "Hacer retiro",

    // PROFILE
    generalInformation: "Información general",
    avatar: "Avatar",
    security: "Seguridad",
    "2fa": "Autenticación de dos factores",
    "2faDescription":
        "La autenticación de dos factores (2FA) es un método de seguridad utilizado para proteger su cuenta www.rodpay.io. El principal objetivo de la autenticación de dos factores es agregar una capa adicional de seguridad más allá de la contraseña tradicional. Al utilizar el correo electrónico como segundo factor de autenticación, incluso si alguien logra obtener su contraseña, no podrá acceder a su cuenta sin tener acceso a su correo electrónico para obtener el código.",
    allow2fa: "Activar la autenticación de dos factores (2FA)",
    setup2FASuccess: "La configuración de la autenticación de dos factores (2FA) se realizó correctamente",
    confirmDisable2fa: "¿Estás seguro de deshabilitar la autenticación de dos factores (2FA)?",
    disable2fa: "Deshabilitar la autenticación de dos factores (2FA)",
    inputCode: "Codigo de entrada",
    "2faSetupGuide":
        "Escanee el siguiente código QR utilizando su aplicación de autenticación, como Google Authenticator, e ingrese el código.",
    passwordRule: "Se requieren 8 caracteres y al menos 1 carácter especial (!@#$&*)",

    // USER
    knowYourCustomer: "Conozca a su cliente",
    nationalID: "Identificación nacional",
    nationalIDNotUploaded: "No se carga el DNI",
    download: "Descargar",
    approve: "Aprobar",
    reject: "Rechazar",
    approved: "Aprobado",
    rejected: "Rechazado",
    companyDUNS: "Empresa DUNS",
    companyDUNSNotUploaded: "La empresa DUNS no está cargada.",

    // USER LOG
    actionLogs: "Registros de acción",
    action: "Acción",
    model: "Modelo",
    detail: "Detalle",
    user_action_create: "Crear",
    user_action_update: "Actualizar",
    user_action_delete: "Borrar",
    user_action_soft_delete: "Borrar",
    user_action_restore: "Restaurar",
    user_action_reset_password: "Restablecer la contraseña",
    user_action_tfa_enable: "Habilitar 2FA",
    user_action_tfa_disable: "Deshabilitar 2FA",
    user_action_update_profile: "Actualización del perfil",
    user_action_invoice_paid: "Factura pagada",
    user_action_invoice_expired: "Factura vencida",
    user_action_withdrawal_in_review: "Revisar el retiro",
    user_action_withdrawal_in_process: "Proceder retiro",
    user_action_withdrawal_finished: "Terminar el retiro",
    user_action_withdrawal_rejected: "Rechazar retiro",

    // PRODUCTS
    image: "Imagen",
    price: "Precio",
    stock: "Existencias",
    newProduct: "Nuevo producto",
    noProduct: "Ningún producto",
    confirmDelete: "¿Estás seguro de eliminar?",
    createProduct: "Crear producto",
    updateProduct: "Actualizar producto",
    selectFile: "Seleccione Archivo",
    totalOrders: "Pedidos totales",
    totalReviews: "Reseñas totales",
    files: "Archivos",
    createNewFile: "Crear nuevo archivo",
    type: "Tipo",
    url: "URL",
    updateFile: "Actualizar archivo",
    file: "Archivo",
    pdf: "PDF",
    video: "Video",
    weight: "Peso",
    rank: "Rango",
    noFile: "Ningún archivo",
    user: "Usuario",
    noReview: "Sin reseña",
    order: "Orden",

    // ORDERS
    initiated: "Iniciado",
    paid: "Pagado",
    shipping: "Envío",
    succeeded: "Tuvo éxito",
    canceled: "Cancelado",
    orderDetail: "Detalle de la orden",
    needToStartDelivery: "Necesita iniciar el proceso de entrega",
    in_process: "En proceso",
    shipment: "Envío",
    note: "Nota",
    country: "País",
    state: "Estado",
    city: "Ciudad",
    apartment: "Departamento",
    postalCode: "Código Postal",
    product: "Producto",
    quantity: "Cantidad",
    transaction: "Transacción",
    noTransactionHistory: "Sin historial de transacciones",
    cancelOrder: "Cancelar orden",
    startDelivery: "Iniciar entrega",
    confirmCancelOrder: "¿Estás seguro de cancelar este pedido?",
    confirm: "Confirmar",
    method: "Método",
    unpaid: "No pagado",
    paidAt: "Pagado en",
    reviews: "Reseñas",
    rating: "Clasificación",
    message: "Mensaje",
};
