import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    commissions: null,
    total: 0,
    filter: {
        page: 1,
        pageSize: 10,
        searchKey: "",
    },
};

const updateCommissions = (state, action) => {
    return updateState(state, {
        commissions: action.commissions,
        total: action.total,
    });
};

const updateFilter = (state, action) => {
    return updateState(state, {
        filter: action.filter,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_COMMISSIONS:
            return updateCommissions(state, action);
        case actionTypes.UPDATE_COMMISSION_FILTER:
            return updateFilter(state, action);
        default:
            return state;
    }
};

export default reducer;
