import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    transactions: null,
    total: 0,
    filter: {
        page: 1,
        pageSize: 10,
    },
};

const updateOrders = (state, action) => {
    return updateState(state, {
        transactions: action.transactions,
        total: action.total,
    });
};

const updateFilter = (state, action) => {
    return updateState(state, {
        filter: action.filter,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_STORE_TRANSACTIONS:
            return updateOrders(state, action);
        case actionTypes.UPDATE_STORE_TRANSACTION_FILTER:
            return updateFilter(state, action);
        default:
            return state;
    }
};

export default reducer;
