import React, { useEffect } from "react";
import Loadable from "react-loadable";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import en from "antd/locale/en_US";
import es from "antd/locale/es_ES";
import pt from "antd/locale/pt_BR";
import fr from "antd/locale/fr_FR";
import it from "antd/locale/it_IT";
import { ConfigProvider, notification } from "antd";
import { Loader } from "./components/base";

import { antdThemes, styledThemes } from "./utils/themes";
import { updateMessage } from "./services/store/actions/theme";

const locales = {
    en,
    es,
    pt,
    fr,
    it,
};

const loading = () => (
    <>
        <Loader />
    </>
);

const Login = Loadable({
    loader: () => import("./pages/Auth/Login/Login"),
    loading,
});

const MainLayout = Loadable({
    loader: () => import("./layouts/MainLayout/MainLayout"),
    loading,
});

function App() {
    const dispatch = useDispatch();
    const { t, i18n: i18nHook } = useTranslation();

    const { message, theme } = useSelector((state) => state.theme);

    useEffect(() => {
        if (message.content !== null) {
            notification[message.type]({
                message: t(message.type).toUpperCase(),
                description: message.content,
                onClose: () => dispatch(updateMessage(null, null)),
            });
        }
    }, [message.content]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ConfigProvider theme={antdThemes[theme]} locale={locales[i18nHook.language] || es}>
            <ThemeProvider theme={styledThemes[theme]}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/*" element={<MainLayout />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </ConfigProvider>
    );
}

export default App;
