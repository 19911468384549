export const TRANSLATIONS_EN = {
    // COMMON
    fieldRequired: "This field is required",
    fieldInvalid: "This field is invalid",
    fieldMaxLimit: "Less than {{limit}} characters are required",
    fieldMaxValueLimit: "Value should be less than {{limit}}",
    fieldMinValueLimit: "Value should be greater than {{limit}}",
    fieldEmailDuplicated: "Email duplicated",
    fieldConfirmPasswordCorrectly: "Confirm password correctly",

    success: "Success",
    warning: "Warning",
    error: "Error",

    // HEADER
    myProfile: "My profile",
    signOut: "Sign Out",

    // SIDER
    dashboard: "Dashboard",
    users: "Users",
    devices: "Devices",
    balance: "Balance",
    transactions: "Transactions",
    withdrawals: "Withdrawals",
    commissions: "Commissions",
    configuration: "Configuration",
    store: "Store",
    products: "Products",
    orders: "Orders",

    // LOGIN
    email: "Email",
    password: "Password",
    keepMeSignedIn: "Keep me signed in",
    signIn: "Sign In",
    getCodeFromYourEmail: "Get code from your email",
    getCodeFromYourAuthenticatorApp: "Get code from your authenticator app",
    enterCodeFromYourEmail: "Enter code from your email",
    verify: "Verify",
    resendCode: "Reenviar código",
    verificationCodeSentSuccessfully: "Verification code was sent successfully",
    accountSuspendedError:
        "This account is suspended, please contact with support to <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",

    // DASHBOARD
    total: "Total",
    wallet: "Wallet",
    incoming: "Incoming",
    commission: "Commission",
    withdrawal: "Withdrawal",
    consumption: "Consumption",
    monthly: "Monthly",
    daily: "Daily",
    inboundCapacity: "Inbound capacity",
    outboundCapacity: "Outbound capacity",

    // USERS
    confirmDeleteUser: "Are you sure to delete this user?",
    delete: "Delete",
    cancel: "Cancel",
    suspendUser: "Suspend user",
    reason: "Reason",
    suspend: "Suspend",
    newUser: "New user",
    searchByEmailOrFullname: "Search by email or fullname",
    selectRole: "Select role",
    all: "All",
    startDate: "Start date",
    finishDate: "Finish date",
    fullname: "Fullname",
    role: "Role",
    phone: "Phone",
    birthday: "Birthday",
    createdAt: "Created at",
    isActive: "Is active",
    active: "Active",
    inactive: "Inactive",
    suspended: "Suspended",
    yes: "Yes",
    no: "No",
    restore: "Restore",
    confirmRestoreUser: "Are you sure to restore this user account?",
    userRole1: "Super manager",
    userRole2: "Manager",
    userRole3: "Admin",
    userRole4: "Member",
    edit: "Edit",
    resetPassword: "Reset password",
    noUser: "No user",
    updateUser: "Update user",
    createUser: "Create user",
    saveChanges: "Save changes",
    create: "Create",
    confirmPassword: "Confirm password",
    reset: "Reset",
    lastLogin: "Last login",
    emailVerified: "Email verified",
    phoneVerified: "Phone verified",

    // DEVICE
    search: "Search",
    paringCode: "Pairing code",
    marketType: "Market type",
    printPurchase: "Print purchase",
    receiveTip: "Receive tip",
    confirmDeleteDevice: "Are you sure to delete this device?",
    noDevices: "No devices",
    selectUser: "Select user",

    // BALANCE
    adminEmail: "Admin email",
    adminName: "Admin name",
    noBalance: "No balance",

    // TRANSACTIONS
    downloadAsExcel: "Download as Excel",
    date: "Date",
    admin: "Admin",
    company: "Company",
    device: "Device",
    description: "Description",
    amount: "Amount",
    currency: "Currency",
    noTransactions: "No transactions",
    outboundLiquidity: "Outbound liquidity",
    inboundLiquidity: "Inbound liquidity",
    hashLog: "Hash log",

    // WITHDRAWALS
    selectStatus: "Select status",
    withdrawalStatus_initiated: "Initiated",
    withdrawalStatus_in_review: "In review",
    withdrawalStatus_in_process: "In process",
    withdrawalStatus_finished: "Finished",
    withdrawalStatus_rejected: "Rejected",
    withdrawalStatusDescription_initiated: "Withdrawal request is created, awaiting for the review by manager",
    withdrawalStatusDescription_in_review: "Withdrawal request is under the review by manager",
    withdrawalStatusDescription_in_process: "Withdrawal request is in process",
    withdrawalStatusDescription_finished: "Withdrawal process is finished",
    withdrawalStatusDescription_rejected: "Withdrawal request is rejected",
    selectManager: "Select manager",
    bank: "Bank",
    account: "Account",
    status: "Status",
    manager: "Manager",
    seeBankDetail: "See bank detail",
    seeBitcoinAddressDetail: "See bitcoin address detail",
    noWithdrawal: "No withdrawal",
    setAsInReview: "Set as In review",
    setAsInProcess: "Set as In process",
    finishWithdrawal: "Finish withdrawal",
    approveWithdrawal: "Approve withdrawal",
    confirmApproveLightningWithdrawal:
        "Are you sure to approve this withdrawal request? Once you approve, withdrawal request will be processed and it is final operation.",
    rejectWithdrawal: "Reject withdrawal",
    bankAccountDetail: "Bank account detail",
    bankName: "Bank name",
    swiftCode: "Swift code",
    accountNumber: "Account number",
    accountName: "Account name",
    address: "Address",
    zipCode: "ZIP code",
    close: "Close",
    bitcoinAccountDetail: "Bitcoin account detail",
    nickName: "Nick name",
    lightningAddress: "Lightning Address",
    lightningAddressDetail: "Lightning Address detail",

    // COMMISSIONS
    commissionType: "Commission type",
    minimumPurchase: "Minimum purchase",
    commissionRunOverThisAmount: "Commission run over this amount",
    fixCommissionAmount: "Fix commission amount",
    commissionPercentage: "Commission percentage",
    noCommission: "No commission",
    updateCommission: "Update commission",
    save: "Save",

    // CONFIGURATION
    baseCommission: "Base commission",
    marketTypes: "Market types",
    name: "Name",
    noMarketTypes: "No market types",
    newMarketType: "New market type",
    createMarketType: "Create market type",
    updateMarketType: "Update market type",
    confirmDeleteMarketType: "Are you sure to delete this market type?",

    // RODPAY WITHDRAWAL
    makeWithdrawal: "Make withdrawal",

    // PROFILE
    generalInformation: "General information",
    avatar: "Avatar",
    security: "Security",
    "2fa": "Two factor authentication",
    "2faDescription":
        "Two-factor authentication (2FA) is a security method used to protect your www.rodpay.io account. The main objective of two-factor authentication is to add an additional layer of security beyond the traditional password. By using email as the second factor of authentication, even if someone manages to obtain your password, they won't be able to access your account without having access to your email to obtain the code.",
    allow2fa: "Activate Two-factor authentication (2FA)",
    setup2FASuccess: "Setting Two-factor authentication (2FA) up succeeded",
    confirmDisable2fa: "Are you sure to disable Two-factor authentication (2FA)?",
    disable2fa: "Disable Two-factor authentication (2FA)",
    inputCode: "Input code",
    "2faSetupGuide":
        "Please scan below QR code using your authenticator app such as Google Authenticator, and input code.",
    passwordRule: "8 characters and at least 1 special character(!@#$&*) are required",

    // USER
    knowYourCustomer: "Know your customer",
    nationalID: "National ID",
    nationalIDNotUploaded: "National ID is not uploaded",
    download: "Download",
    approve: "Approve",
    reject: "Reject",
    approved: "Approved",
    rejected: "Rejected",
    companyDUNS: "Company DUNS",
    companyDUNSNotUploaded: "Company DUNS is not uploaded",

    // USER LOG
    actionLogs: "Action logs",
    action: "Action",
    model: "Model",
    detail: "Detail",
    user_action_create: "Create",
    user_action_update: "Update",
    user_action_delete: "Delete",
    user_action_soft_delete: "Delete",
    user_action_restore: "Restore",
    user_action_reset_password: "Reset password",
    user_action_tfa_enable: "Enable 2FA",
    user_action_tfa_disable: "Disable 2FA",
    user_action_update_profile: "Update profile",
    user_action_invoice_paid: "Invoice paid",
    user_action_invoice_expired: "Invoice expired",
    user_action_withdrawal_in_review: "Review withdrawal",
    user_action_withdrawal_in_process: "Proceed withdrawal",
    user_action_withdrawal_finished: "Finish withdrawal",
    user_action_withdrawal_rejected: "Reject withdrawal",

    // PRODUCTS
    image: "Image",
    price: "Price",
    stock: "Stock",
    newProduct: "New product",
    noProduct: "No product",
    confirmDelete: "Are you sure to delete?",
    createProduct: "Create product",
    updateProduct: "Update product",
    selectFile: "Select file",
    totalOrders: "Total orders",
    totalReviews: "Total reviews",
    files: "Files",
    createNewFile: "Create new file",
    type: "Type",
    url: "URL",
    updateFile: "Update file",
    file: "File",
    pdf: "PDF",
    video: "Video",
    weight: "Weight",
    rank: "Rank",
    noFile: "No file",
    user: "User",
    noReview: "No review",
    order: "Order",

    // ORDERS
    initiated: "Initiated",
    paid: "Paid",
    shipping: "Shipping",
    succeeded: "Succeeded",
    canceled: "Canceled",
    orderDetail: "Order detail",
    needToStartDelivery: "Need to start delivery process",
    in_process: "In process",
    shipment: "Shipment",
    note: "Note",
    country: "Country",
    state: "State",
    city: "City",
    apartment: "Apartment",
    postalCode: "Postal code",
    product: "Product",
    quantity: "Quantity",
    transaction: "Transaction",
    noTransactionHistory: "No transaction history",
    cancelOrder: "Cancel order",
    startDelivery: "Start delivery",
    confirmCancelOrder: "Are you sure to cancel this order?",
    confirm: "Confirm",
    method: "Method",
    unpaid: "Unpaid",
    paidAt: "Paid at",
    reviews: "Reviews",
    rating: "Rating",
    message: "Message",
};
