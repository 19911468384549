/* eslint-disable max-len */
export const TRANSLATIONS_IT = {
    // COMMON
    fieldRequired: "Questo campo è obbligatorio",
    fieldInvalid: "Questo campo non è valido",
    fieldMaxLimit: "Sono richiesti meno di {{limit}} caratteri",
    fieldMaxValueLimit: "Il valore dovrebbe essere minore di {{limit}}",
    fieldMinValueLimit: "Il valore dovrebbe essere maggiore di {{limit}}",
    fieldEmailDuplicated: "Email duplicata",
    fieldConfirmPasswordCorrectly: "Conferma la password correttamente",

    success: "Successo",
    warning: "Avvertimento",
    error: "Errore",

    // HEADER
    myProfile: "Il mio profilo",
    signOut: "Disconnessione",

    // SIDER
    dashboard: "Pannello di controllo",
    users: "Utenti",
    devices: "Dispositivi",
    balance: "Bilancia",
    transactions: "Transazioni",
    withdrawals: "Prelievi",
    commissions: "Commissioni",
    configuration: "Configurazione",
    store: "Negozio",
    products: "Prodotti",
    orders: "Ordini",

    // LOGIN
    email: "E-mail",
    password: "Parola d'ordine",
    keepMeSignedIn: "Tienimi connesso",
    signIn: "Registrazione",
    getCodeFromYourEmail: "Ottieni il codice dalla tua email",
    getCodeFromYourAuthenticatorApp: "Ottieni il codice dalla tua app di autenticazione",
    enterCodeFromYourEmail: "Inserisci il codice dalla tua email",
    verify: "Verificare",
    resendCode: "Codice di rispedizione",
    verificationCodeSentSuccessfully: "Il codice di verifica è stato inviato con successo",
    accountSuspendedError:
        "Questo account è sospeso, contatta l'assistenza a <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",

    // DASHBOARD
    total: "Totale",
    wallet: "Portafoglio",
    incoming: "In arrivo",
    commission: "Commissione",
    withdrawal: "Ritiro",
    consumption: "Consumo",
    monthly: "Mensile",
    daily: "Quotidiano",
    inboundCapacity: "Capacità in entrata",
    outboundCapacity: "Capacità in uscita",

    // USERS
    confirmDeleteUser: "Sei sicuro di eliminare questo utente?",
    delete: "Eliminare",
    cancel: "Annulla",
    suspendUser: "Sospendere l'utente",
    reason: "Motivo",
    suspend: "Sospendere",
    newUser: "Nuovo utente",
    searchByEmailOrFullname: "Cerca per email o nome completo",
    selectRole: "Seleziona ruolo",
    all: "Tutto",
    startDate: "Data d'inizio",
    finishDate: "Data di fine",
    fullname: "Nome e cognome",
    role: "Ruolo",
    phone: "Telefono",
    birthday: "Compleanno",
    createdAt: "Creato a",
    isActive: "È attivo",
    active: "Attivo",
    inactive: "Inattivo",
    suspended: "Sospeso",
    yes: "SÌ",
    no: "No",
    restore: "Ristabilire",
    confirmRestoreUser: "Sei sicuro di ripristinare questo account utente?",
    userRole1: "Super manager",
    userRole2: "Manager",
    userRole3: "Amministratore",
    userRole4: "Membro",
    edit: "Modificare",
    resetPassword: "Resetta la password",
    noUser: "Nessun utente",
    updateUser: "Aggiorna utente",
    createUser: "Creare un utente",
    saveChanges: "Salvare le modifiche",
    create: "Creare",
    confirmPassword: "Conferma password",
    reset: "Ripristina",
    lastLogin: "Ultimo accesso",
    emailVerified: "Email verificata",
    phoneVerified: "Telefono verificato",

    // DEVICE
    search: "Ricerca",
    paringCode: "Codice di abbinamento",
    marketType: "Tipo di mercato",
    printPurchase: "Acquisto stampa",
    receiveTip: "Ricevi mancia",
    confirmDeleteDevice: "Vuoi eliminare questo dispositivo?",
    noDevices: "Nessun dispositivo",
    selectUser: "Seleziona utente",

    // BALANCE
    adminEmail: "E-mail dell'amministratore",
    adminName: "Nome amministratore",
    noBalance: "Nessun equilibrio",

    // TRANSACTIONS
    downloadAsExcel: "Scarica come Excel",
    date: "Data",
    admin: "Amministratore",
    company: "Azienda",
    device: "Dispositivo",
    description: "Descrizione",
    amount: "Quantità",
    currency: "Valuta",
    noTransactions: "Nessuna transazione",
    outboundLiquidity: "Liquidità in uscita",
    inboundLiquidity: "Liquidità in entrata",
    hashLog: "Registro hash",

    // WITHDRAWALS
    selectStatus: "Seleziona stato",
    withdrawalStatus_initiated: "Avviato",
    withdrawalStatus_in_review: "In revisione",
    withdrawalStatus_in_process: "In corso",
    withdrawalStatus_finished: "Finito",
    withdrawalStatus_rejected: "Respinto",
    withdrawalStatusDescription_initiated:
        "Viene creata la richiesta di prelievo, in attesa della revisione da parte del gestore",
    withdrawalStatusDescription_in_review: "La richiesta di prelievo è in fase di revisione da parte del gestore",
    withdrawalStatusDescription_in_process: "La richiesta di prelievo è in corso",
    withdrawalStatusDescription_finished: "Il processo di prelievo è terminato",
    withdrawalStatusDescription_rejected: "La richiesta di prelievo viene respinta",
    selectManager: "Seleziona gestore",
    bank: "Banca",
    account: "Account",
    status: "Stato",
    manager: "Manager",
    seeBankDetail: "Vedi coordinate bancarie",
    seeBitcoinAddressDetail: "Vedi i dettagli dell'indirizzo bitcoin",
    noWithdrawal: "Nessun ritiro",
    setAsInReview: "Imposta come In revisione",
    setAsInProcess: "Imposta come In elaborazione",
    finishWithdrawal: "Termina il ritiro",
    approveWithdrawal: "Approvare il ritiro",
    confirmApproveLightningWithdrawal:
        "Sei sicuro di approvare questa richiesta di prelievo? Una volta approvata, la richiesta di prelievo verrà elaborata e sarà un'operazione finale.",
    rejectWithdrawal: "Rifiuta il ritiro",
    bankAccountDetail: "Dettagli del conto bancario",
    bankName: "Nome della banca",
    swiftCode: "Codice SWIFT",
    accountNumber: "Numero di conto",
    accountName: "Nome utente",
    address: "Indirizzo",
    zipCode: "Cap",
    close: "Vicino",
    bitcoinAccountDetail: "Dettagli dell'account Bitcoin",
    nickName: "Soprannome",
    lightningAddress: "Lightning Indirizzo",
    lightningAddressDetail: "Lightning Dettagli indirizzo",

    // COMMISSIONS
    commissionType: "Tipo di commissione",
    minimumPurchase: "Acquisto minimo",
    commissionRunOverThisAmount: "La commissione supera questo importo",
    fixCommissionAmount: "Fissa l'importo della commissione",
    commissionPercentage: "Percentuale della commissione",
    noCommission: "Nessuna commissione",
    updateCommission: "Commissione di aggiornamento",
    save: "Salva",

    // CONFIGURATION
    baseCommission: "Commissione di base",
    marketTypes: "Tipi di mercato",
    name: "Nome",
    noMarketTypes: "Nessun tipo di mercato",
    newMarketType: "Nuovo tipo di mercato",
    createMarketType: "Crea tipo di mercato",
    updateMarketType: "Aggiorna il tipo di mercato",
    confirmDeleteMarketType: "Sei sicuro di eliminare questo tipo di mercato?",

    // RODPAY WITHDRAWAL
    makeWithdrawal: "Effettua il prelievo",

    // PROFILE
    generalInformation: "Informazioni generali",
    avatar: "Avatar",
    security: "Sicurezza",
    "2fa": "Autenticazione a due fattori",
    "2faDescription":
        "L'autenticazione a due fattori (2FA) è un metodo di sicurezza utilizzato per proteggere il tuo account www.rodpay.io. L'obiettivo principale dell'autenticazione a due fattori è aggiungere un ulteriore livello di sicurezza oltre alla password tradizionale. Utilizzando l'e-mail come secondo fattore di autenticazione, anche se qualcuno riesce a ottenere la tua password, non potrà accedere al tuo account senza avere accesso alla tua e-mail per ottenere il codice.",
    allow2fa: "Attiva l'autenticazione a due fattori (2FA)",
    setup2FASuccess: "L'impostazione dell'autenticazione a due fattori (2FA) è riuscita",
    confirmDisable2fa: "Sei sicuro di disabilitare l'autenticazione a due fattori (2FA)?",
    disable2fa: "Disattiva l'autenticazione a due fattori (2FA)",
    inputCode: "Inserisci il codice",
    "2faSetupGuide":
        "Esegui la scansione del codice QR di seguito utilizzando l'app di autenticazione come Google Authenticator e inserisci il codice.",
    passwordRule: "Sono richiesti 8 caratteri e almeno 1 carattere speciale (!@#$&*).",

    // USER
    knowYourCustomer: "Conosci il tuo cliente",
    nationalID: "Carta d'identità nazionale",
    nationalIDNotUploaded: "L'ID nazionale non è stato caricato",
    download: "Scaricamento",
    approve: "Approvare",
    reject: "Rifiutare",
    approved: "Approvato",
    rejected: "Respinto",
    companyDUNS: "Azienda DUNS",
    companyDUNSNotUploaded: "La società DUNS non è caricata",

    // USER LOG
    actionLogs: "Registri delle azioni",
    action: "Azione",
    model: "Modello",
    detail: "Dettaglio",
    user_action_create: "Creare",
    user_action_update: "Aggiornamento",
    user_action_delete: "Eliminare",
    user_action_soft_delete: "Eliminare",
    user_action_restore: "Ristabilire",
    user_action_reset_password: "Resetta la password",
    user_action_tfa_enable: "Abilita 2FA",
    user_action_tfa_disable: "Disabilita 2FA",
    user_action_update_profile: "Aggiorna il profilo",
    user_action_invoice_paid: "Fattura pagata",
    user_action_invoice_expired: "Fattura scaduta",
    user_action_withdrawal_in_review: "Revisione del ritiro",
    user_action_withdrawal_in_process: "Procedere al ritiro",
    user_action_withdrawal_finished: "Termina il ritiro",
    user_action_withdrawal_rejected: "Rifiutare il ritiro",

    // PRODUCTS
    image: "Immagine",
    price: "Prezzo",
    stock: "Azione",
    newProduct: "Nuovo prodotto",
    noProduct: "Nessun prodotto",
    confirmDelete: "Sei sicuro di voler eliminare?",
    createProduct: "Crea prodotto",
    updateProduct: "Aggiorna prodotto",
    selectFile: "Seleziona il file",
    totalOrders: "Ordini totali",
    totalReviews: "Recensioni totali",
    files: "File",
    createNewFile: "Crea nuovo file",
    type: "Tipo",
    url: "URL",
    updateFile: "Aggiorna file",
    file: "File",
    pdf: "PDF",
    video: "Video",
    weight: "Peso",
    rank: "Rango",
    noFile: "Nessun file",
    user: "Utente",
    noReview: "Nessuna recensione",
    order: "Ordine",

    // ORDERS
    initiated: "Avviato",
    paid: "Pagato",
    shipping: "Spedizione",
    succeeded: "Successo",
    canceled: "Annullato",
    orderDetail: "Dettagli ordine",
    needToStartDelivery: "È necessario avviare il processo di consegna",
    in_process: "In corso",
    shipment: "Spedizione",
    note: "Nota",
    country: "Paese",
    state: "Stato",
    city: "Città",
    apartment: "Appartamento",
    postalCode: "Codice Postale",
    product: "Prodotto",
    quantity: "Quantità",
    transaction: "Transazione",
    noTransactionHistory: "Nessuna cronologia delle transazioni",
    cancelOrder: "Annulla Ordine",
    startDelivery: "Inizia la consegna",
    confirmCancelOrder: "Sei sicuro di voler annullare questo ordine?",
    confirm: "Confermare",
    method: "Metodo",
    unpaid: "Non pagato",
    paidAt: "Pagato a",
    reviews: "Recensioni",
    rating: "Valutazione",
    message: "Messaggio",
};
