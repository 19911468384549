import { theme } from "antd";

import {
    bhColorBlack,
    bhColorDark,
    bhColorDarkGray,
    bhColorError,
    bhColorWarning,
    bhColorSuccess,
    bhColorSuccess1,
    bhColorGray,
    bhColorLightPrimary,
    bhColorPrimary,
    bhColorVioletGray,
    bhColorWhite,
} from "./constants";

export const antdThemeLight = {
    algorithm: theme.defaultAlgorithm,
    token: {
        colorPrimary: bhColorPrimary,
        colorLink: bhColorPrimary,
        colorBgLayout: bhColorWhite,
        colorTextBase: bhColorDark,
        colorText: bhColorDark,

        borderRadius: 6,

        controlHeight: 36,

        fontFamily: "Roboto",

    },
};

export const antdThemeDark = {
    algorithm: theme.darkAlgorithm,
    token: {
        colorPrimary: bhColorPrimary,
        colorLink: bhColorPrimary,
        colorBgLayout: bhColorBlack,

        borderRadius: 6,

        controlHeight: 36,

        fontFamily: "Roboto",

    },
};

export const lightTheme = {
    colorPrimary: bhColorPrimary,
    colorLightPrimary: bhColorLightPrimary,
    colorRed: bhColorError,
    colorOrange: bhColorWarning,
    colorPurple: bhColorSuccess,
    colorBlue: bhColorSuccess1,

    colorWhite: bhColorWhite,
    colorGray: bhColorGray,
    colorDarkGray: bhColorDarkGray,
    colorVioletGray: bhColorVioletGray,
    colorDark: bhColorDark,
    colorBlack: bhColorBlack,

    antdBorderColor: "#d9d9d9",
    antdInputHoverBorderColor: "#79a1a6",
    antdInputFocusBoxShadow: "rgb(55 5 255 / 6%)",
    antdInputColor: "rgba(0, 0, 0, 0.88)",

    // Component colors
    authBgColor: "#FFF",
    authLeftBgColor: "#13276D",
    siderBgColor: bhColorGray,
    siderMenuActiveColor: bhColorPrimary,
    siderMenuTextColor: "#5a5a5a",
    thBackgroundColor: bhColorLightPrimary,
};

export const darkTheme = {
    colorPrimary: bhColorPrimary,
    colorLightPrimary: bhColorLightPrimary,
    colorRed: bhColorError,
    colorOrange: bhColorWarning,
    colorPurple: bhColorSuccess,
    colorBlue: bhColorSuccess1,

    colorWhite: bhColorBlack,
    colorGray: bhColorGray,
    colorDarkGray: bhColorDarkGray,
    colorVioletGray: bhColorVioletGray,
    colorDark: bhColorDark,
    colorBlack: bhColorWhite,

    antdBorderColor: "#d9d9d9",
    antdInputHoverBorderColor: "#79a1a6",
    antdInputFocusBoxShadow: "rgb(55 5 255 / 6%)",
    antdInputColor: "rgba(0, 0, 0, 0.88)",

    // Component colors
    authBgColor: "#000",
    authLeftBgColor: "rgba(255, 255, 255, 0.04)",
    siderBgColor: "rgba(255, 255, 255, 0.08)",
    siderMenuActiveColor: "rgba(255, 255, 255, 0.25)",
    siderMenuTextColor: "#bababa",
    thBackgroundColor: "rgba(210, 224, 226, 0.15)",
};

export const antdThemes = {
    light: antdThemeLight,
    dark: antdThemeDark,
};

export const styledThemes = {
    light: lightTheme,
    dark: darkTheme,
};
