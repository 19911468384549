import { saveAs } from "file-saver";

export const checkValidEmail = (email) => {
    const regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
};

export const checkValidPassword = (password) => {
    return RegExp(/^(?=.*[a-zA-Z0-9])(?=.*[!@#$&*]).{8,}$/).test(password);
};

export const getErrorMessage = (e) => {
    if (e.message === "Network Error") {
        return e.message;
    } else {
        if (e.response) {
            if (e.response.status === 401) {
                return "You are not authenticated, please login again!";
            } else if (e.response.status === 403) {
                return "You have no permission!";
            } else if (e.response.status === 404) {
                return "URL not found";
            } else if (e.response.status === 405) {
                return "Bad request";
            } else if (e.response.status === 500) {
                return "API error";
            } else {
                if (e.response?.data?.detail) {
                    return e.response.data.detail;
                } else {
                    return "Bad request";
                }
            }
        } else {
            return "API error";
        }
    }
};

export const fileToBase64Handler = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
    });
};

export const getSecuredEmail = (email) => {
    if (!email) return "";
    const arr = email.split("@");
    return `${arr[0][0]}********@${arr[1]}`;
};

export const getFileNameFromURL = (url) => {
    if (!url) return "";
    const urlArr = url.split("/");
    return urlArr[urlArr.length - 1];
};

export const downloadFileHandler = (url, filename) => saveAs(url, filename);
