export const UPDATE_SIDER_COLLAPSED = "UPDATE_SIDER_COLLAPSED";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";

export const UPDATE_WALLETS = "UPDATE_WALLETS";
export const UPDATE_WALLET_FILTER = "UPDATE_WALLET_FILTER";

export const UPDATE_ALL_USERS = "UPDATE_ALL_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USER_FILTER = "UPDATE_USER_FILTER";
export const UPDATE_MANAGERS = "UPDATE_MANAGERS";

export const UPDATE_DEVICES = "UPDATE_DEVICES";
export const UPDATE_DEVICE_FILTER = "UPDATE_DEVICE_FILTER";

export const UPDATE_TRANSACTIONS = "UPDATE_TRANSACTIONS";
export const UPDATE_TRANSACTION_FILTER = "UPDATE_TRANSACTION_FILTER";

export const UPDATE_WITHDRAWALS = "UPDATE_WITHDRAWALS";
export const UPDATE_WITHDRAWAL_FILTER = "UPDATE_WITHDRAWAL_FILTER";

export const UPDATE_COMMISSIONS = "UPDATE_COMMISSIONS";
export const UPDATE_COMMISSION_FILTER = "UPDATE_COMMISSION_FILTER";

export const UPDATE_BASE_COMMISSION = "UPDATE_BASE_COMMISSION";
export const UPDATE_ALL_MARKET_TYPES = "UPDATE_ALL_MARKET_TYPES";
export const UPDATE_MARKET_TYPES = "UPDATE_MARKET_TYPES";
export const UPDATE_MARKET_TYPE_FILTER = "UPDATE_MARKET_TYPE_FILTER";

export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const UPDATE_PRODUCT_FILTER = "UPDATE_PRODUCT_FILTER";

export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const UPDATE_ORDER_FILTER = "UPDATE_ORDER_FILTER";

export const UPDATE_STORE_TRANSACTIONS = "UPDATE_STORE_TRANSACTIONS";
export const UPDATE_STORE_TRANSACTION_FILTER = "UPDATE_STORE_TRANSACTION_FILTER";
