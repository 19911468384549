import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    allUsers: null,
    users: null,
    total: 0,
    filter: {
        page: 1,
        pageSize: 10,
        searchKey: "",
        role: null,
        startDate: null,
        finishDate: null,
    },
    managers: null,
};

const updateAllUsers = (state, action) => {
    return updateState(state, {
        allUsers: action.allUsers,
    });
};

const updateUsers = (state, action) => {
    return updateState(state, {
        users: action.users,
        total: action.total,
    });
};

const updateFilter = (state, action) => {
    return updateState(state, {
        filter: action.filter,
    });
};

const updateManagers = (state, action) => {
    return updateState(state, {
        managers: action.managers,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ALL_USERS:
            return updateAllUsers(state, action);
        case actionTypes.UPDATE_USERS:
            return updateUsers(state, action);
        case actionTypes.UPDATE_USER_FILTER:
            return updateFilter(state, action);
        case actionTypes.UPDATE_MANAGERS:
            return updateManagers(state, action);
        default:
            return state;
    }
};

export default reducer;
